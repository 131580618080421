import { Section } from 'components/_layout/Section/Section';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { HRBanner } from 'sections/Career/HRBanner/HRBanner';
import styled, { css } from 'styled-components';
import { B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';
import { drawTextWithLineBreaks } from 'utils/strings';

export const StepContainer = styled(Common.Div)(
    () =>
        css`
            border-left: 1px solid white;
            display: flex;
            flex-direction: column;
            flex-grow: 1;
        `,
);

export const StepHead = styled(Common.Div)(
    ({ theme: { colors, spacing } }) =>
        css`
            background: ${colors.background};
            display: flex;
            align-items: center;
            padding: ${spacing(3)};
            height: 78px;
        `,
);

export const StepBody = styled(Common.Div)(
    ({ theme: { spacing }, isMobile }) =>
        css`
            padding: ${spacing(3)};
            ${isMobile &&
            css`
                min-height: 222px;
            `};
        `,
);

export const StepGradientHead = styled(Common.Div)(
    ({ theme: { gradients, spacing } }) =>
        css`
            background: ${gradients.teal};
            display: flex;
            align-items: center;
            padding: ${spacing(3)};
            height: 78px;
        `,
);

const TEXT_SEND_US_CV = `and fill in the initial\n form, will see if\n there is a match`;
const TEXT_WAIT_FOR_US = `to contact you`;
const TEXT_FIRST_INTERVIEW = `talk to us live\n and (if applicable)\n complete the\n recruitment task`;
const TEXT_SECOND_INTERVIEW = `talk to us about further\n details and accept our\n offer`;
const TEXT_WELCOME_ON_BOARD = `You live and breathe our\n atmosphere and our culture`;

export const TheRecruitmentProcessSection = () => {
    const isMd = useBreakpoint('md');
    return (
        <Section title="The recruitment process">
            <Common.Div flex={isMd ? 'column' : 'row'} mt={8} mb={8}>
                <StepContainer>
                    <StepHead>
                        <B3>Send us CV</B3>
                    </StepHead>
                    <StepBody>
                        <B3>{drawTextWithLineBreaks(TEXT_SEND_US_CV)}</B3>
                    </StepBody>
                </StepContainer>
                <StepContainer>
                    <StepHead>
                        <B3>Wait for us</B3>
                    </StepHead>
                    <StepBody>
                        <B3>{drawTextWithLineBreaks(TEXT_WAIT_FOR_US)}</B3>
                    </StepBody>
                </StepContainer>
                <StepContainer>
                    <StepHead>
                        <B3>First interview</B3>
                    </StepHead>
                    <StepBody>
                        <B3>{drawTextWithLineBreaks(TEXT_FIRST_INTERVIEW)}</B3>
                    </StepBody>
                </StepContainer>
                <StepContainer>
                    <StepHead>
                        <B3>Second interview</B3>
                    </StepHead>
                    <StepBody>
                        <B3>{drawTextWithLineBreaks(TEXT_SECOND_INTERVIEW)}</B3>
                    </StepBody>
                </StepContainer>
                <StepContainer>
                    <StepGradientHead>
                        <B3>Welcome on board!</B3>
                    </StepGradientHead>
                    <StepBody>
                        <B3>{drawTextWithLineBreaks(TEXT_WELCOME_ON_BOARD)}</B3>
                    </StepBody>
                </StepContainer>
            </Common.Div>
            <HRBanner />
        </Section>
    );
};
