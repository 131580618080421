import { CommonMargin } from 'components/_layout/CommonMargin';
import { Section } from 'components/_layout/Section/Section';
import SEO from 'components/_layout/Seo';
import React from 'react';
import { OpenPositions } from 'sections/Career/OpenPositions/OpenPositions';
import { PerksAndBenefits } from 'sections/Career/PerksAndBenefits/PerksAndBenefits';
import { TheRecruitmentProcessSection } from 'sections/Career/TheRecruitmentProcessSection';
import { B3 } from 'styles/Typography.styled';

export default () => {
    return (
        <CommonMargin>
            <SEO
                title="Career"
                meta={[
                    {
                        name: 'keywords',
                        content:
                            'dedicated development team services, dedicated software development team, flutter app developer',
                    },
                ]}
                description={
                    'Our dedicated development team services provide businesses with a dedicated software development team tailored to meet specific project needs. With expertise in diverse technologies, including Flutter, we offer skilled Flutter app developers to create high-performance mobile applications. Our focus is on delivering customized solutions that enhance productivity and streamline processes, ensuring your project is completed on time and within budget. Partner with us for comprehensive support and innovative software development tailored to your business goals.'
                }
            />
            <Section title="Join the team!" isFirst variant="secondary">
                <B3 mt={4.5}>
                    Be among people who want a better world and the great causes
                    making it happen. We work across multiple areas and partner
                    with a wide variety of inspirational charities and social
                    enterprises. We believe that through stronger relationships,
                    we can all make more of an impact.
                </B3>
            </Section>
            <PerksAndBenefits />
            <OpenPositions />
            <TheRecruitmentProcessSection />
        </CommonMargin>
    );
};
