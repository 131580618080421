import { Section } from 'components/_layout/Section/Section';
import React from 'react';
import {
    GridWrapper,
    ItemWrapper,
} from 'sections/Career/PerksAndBenefits/PerksAndBenefits.styled';
import { PerksAndBenefitsData } from 'shared/data/PerksAndBenefits.data';
import shortId from 'shortid';
import { B1, B3 } from 'styles/Typography.styled';

export const PerksAndBenefits = () => {
    return (
        <Section title="Perks & Benefits">
            <GridWrapper>
                {PerksAndBenefitsData.map(({ description, title }) => (
                    <ItemWrapper key={shortId.generate()}>
                        <B1>{title}</B1>
                        <B3>{description}</B3>
                    </ItemWrapper>
                ))}
            </GridWrapper>
        </Section>
    );
};
