import BasiaImage from 'assets/images/About/people/basia.png';
import * as Common from 'components/_universal/Common';
import React from 'react';
import { B0, B3 } from 'styles/Typography.styled';
import { useBreakpoint } from 'utils/hooks/useBreakpoints';

export const HRBanner = () => {
    const isMd = useBreakpoint('md');
    const isSm = useBreakpoint('sm');

    return (
        <Common.Div
            flex={isSm ? 'column' : 'row'}
            gap={isMd ? '48px' : '128px'}
            mt={isMd ? 6 : 12}
        >
            <Common.Div>
                <Common.Svg src={BasiaImage} w="285px" />
                <B0 mt={2}>Barbara Warchulka</B0>
                <B3>HR Manager</B3>
            </Common.Div>
            <Common.Div>
                <B0 mb={2}>Hi! Nice to meet you.</B0>
                <B3 maxW="500px">
                    At Codahead we place a strong emphasis on the values of open
                    communication, teamwork, collaboration, and mutual respect.
                    We believe that the success of our team is built on the
                    foundation of these values, and we strive to create a work
                    environment where every individual feels valued and
                    supported. We know how important unique strengths of each
                    team member contribute to overall success of our company. By
                    fostering a culture of teamwork and collaboration, we are
                    able to leverage the diverse talents of our team members to
                    achieve our goals and exceed expectations. We make sure that
                    everyone feels comfortable at Codahead, whether you are
                    working from our office in Cracow or from different corners
                    of the world.
                </B3>
            </Common.Div>
        </Common.Div>
    );
};
