import { mediaQueries } from 'shared/breakpoints';
import styled, { css } from 'styled-components';
import { H2, SectionText, SectionWrapper } from 'styles/Typography.styled';
import * as Common from 'components/_universal/Common';

export const Wrapper = styled.div(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.section};
        `,
);

export const ContentWrapper = styled(SectionWrapper)`
    flex-direction: column;
    padding: 100px 0;
    text-align: center;

    ${mediaQueries.md} {
        padding: 50px 32px;
    }
`;

export const Title = styled(H2)(
    ({ theme: { colors } }) =>
        css`
            color: ${colors.text};
            margin-bottom: 56px;
        `,
);

export const GridWrapper = styled.div`
    margin-top: 130px;
    display: grid;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
    grid-template-columns: repeat(auto-fill, 255px);
    grid-template-rows: repeat(auto-fill, 255px);
    width: 100%;
`;

export const ItemWrapper = styled(Common.Div)(
    ({ theme: { colors } }) =>
        css`
            background: ${colors.background};
          justify-content: center;
          display: flex;
          flex-direction: column;
          height: 255px;
          width: 255px;
          padding-left: 30px;
          padding-right: 30px;
          gap: 24px;
            }
        `,
);

export const ItemTitle = styled(SectionText)(
    ({ theme: { colors, fontFamily, fontSizes } }) =>
        css`
            color: ${colors.section};
            font-family: ${fontFamily.primary};
            font-size: ${fontSizes.f16};
            margin: 26px 0 8px 0;
            text-transform: uppercase;
            text-align: left;

            ${mediaQueries.xs} {
                font-size: ${fontSizes.f11};
                line-height: 16px;
                margin: 20px 0 8px 0;
            }
        `,
);

export const IconImage = styled.img`
    height: 56px;
    width: 56px;

    ${mediaQueries.sm} {
        height: 45px;
        width: 45px;
    }
`;
