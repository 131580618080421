import Goodies from 'assets/images/Career/Goodies_icon.svg';
import Travel from 'assets/images/Career/Travel_icon.svg';

export const PerksAndBenefitsData = [
    {
        description: 'you can always ask about everything',
        title: 'Leader’s support',
    },
    {
        description: 'you have the opportunity to show your skills',
        title: 'Innovative projects',
    },
    {
        description: 'in our company your opinion counts',
        title: 'Challenging position',
    },
    {
        description: 'beer Fridays every week',
        title: 'Team buliding',
    },
    {
        description: 'your career path can change direction',
        title: 'Option to switch',
    },
    {
        description: 'work starts between 8 and 10 am',
        title: 'Flexible hours',
    },
    {
        description: 'our work environment is casual and flexible',
        title: 'Culture-oriented team',
    },
    {
        description: 'free space for your car (bicycle or electric)',
        title: 'Free parking space',
    },
    {
        description: 'comfortable, air-filtered office with chillout space',
        title: 'Our space',
    },
    {
        description: 'fresh fruits, snacks and fizzy drinks',
        title: 'Goodies',
    },
    {
        description: 'your pets are welcome in our office',
        title: 'Animal friendly',
    },
    {
        description: 'visits to our clients` offices (mostly to the U.S.)',
        title: 'Travel',
    },
    {
        description: 'you can work from home if you like',
        title: 'Remote work',
    },
    {
        description: 'we can help you to move to Cracow',
        title: 'Relocation package',
    },
    {
        description: 'we take care of your and your family`s health',
        title: 'Private medical care',
    },
    {
        description: 'important facts we celebrate with you',
        title: 'Welcome package',
    },
    {
        description: 'twice a week with a native speaker',
        title: 'English classes',
    },
    {
        description: 'you can learn with company platforms and library',
        title: 'Access to e-learning',
    },
    {
        description: 'conferences and courses in Poland and abroad',
        title: 'Training package',
    },
    {
        description: 'we appreciate recommending qualified candidates',
        title: 'Referral program',
    },
];
