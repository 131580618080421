import { Section } from 'components/_layout/Section/Section';
import React, { useEffect } from 'react';

export const OpenPositions = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src =
            'https://codahead.elevato.net/services/scripts/ElevatoWidgetJobOffers.js?WidgetId=7&WidgetGuid=25877188-53C9-429C-8B9D-CC2982BB688B';
        script.async = true;
        document.body.appendChild(script);
    }, []);

    return (
        <Section title="Open Positions" variant="secondary">
            <div id="elevato-widget" />
        </Section>
    );
};
